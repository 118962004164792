#info-modal{
    position: absolute;
    top: 80px;
    right: 0;
    background-color: #fefefe;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    text-align: left;
    pointer-events: none;

    animation: slide-fade .2s;
}

#info-button{
    border-radius: 50%;
    border: solid 1px #dfdfdf;
    width: 30px;
    height: 30px;
    font-family: monospace;

    transition: .2s;
}
#info-button:hover{
    background-color: #d3d3d3;
    color: white;
}

#details p{
    font-size: 12px;
    opacity: 0.7;
}

#dbIndicator{
    position: absolute;
    top: 0;
    left: -60px;

    padding: 0 4px;
    min-width: 50px;
    border-radius: 10px;

    display: grid;
    grid-template-columns: 100fr;
}
.dbScale{
    margin: 6px 0;
}
.metric{
    font-family: Helvetica;
    text-align: center;
    margin: 2px 0;
    color: white;
    border-radius: 5px;
    background: black;
}
.dbScale p{
    background: green;
    margin: 2px 0;
    padding: 4px 8px;
    text-align: center;
    font-size: 12px;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    color: white;
    border-radius: 4px;
}

.keyboardKey{
    margin: 5px;
    font-size: 16px;
}
.keyboardKey span{
    padding: 6px 10px;
    display: inline-block;
    min-width: 15px;
    min-height: 20px;
    text-align: center;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
}

@keyframes slide-fade{
    from{
        transform: translateY(-40px);
        opacity: 0;
    }
    to{
        transform: translateY(0px);
        opacity: 1;
    }
}