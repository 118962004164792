.App {
  width: 100%;
  text-align: center;
  overflow: hidden;
}

nav{
  position: absolute;

  min-height: 50px;
  width: 100%;
  padding: 5px 0;
  background-color: #ffffffef;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

nav > div{
  margin: 0 10px;
  user-select: none;
}

#modelBox{
  margin: 0;
  padding: 2px 5px;
  color: white;
  background: #2ab7ff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  padding: 3px;
  /* border: solid 3px #914639; */
  border-radius: 40px;
  filter: drop-shadow(0 0 2px #33333333);
  cursor: pointer;
}
#modelBox:focus{
  background: rgb(255, 165, 149);
}

#dateBox-wrapper{
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #ededed;
  height: 40px;
  border-radius: 15px 15px 0 0;
}
#dateBox-timezone{
  position: absolute;
  background: #333;
  color: white;
  border-radius: 0 0 15px 15px;
  top: 0px;
  opacity: 0.4;
  font-size: 8px;
  width: 50px;
}
.left-button, .right-button{
  width: 40px;
  height: 100%;
  
  background: transparent;
  border: none;
  opacity: 0.7;
  font-size: 20px;
  font-family: monospace;
  color: #333;

  transition: .2s;
}
.left-button:hover, .right-button:hover{
  opacity: 1;
  background-color: #d3d3d3;
}
.left-button{
  border-radius: 15px 0 0 15px;
}
.right-button{
  border-radius: 0 15px 15px 0;
}
/* .react-datepicker__input-container{
  cursor: pointer;
}
.react-datepicker__input-container input{
  background: transparent;
  text-align: center;
  border: none;
  padding: 0;
  height: 40px;
  line-height: 40px;
  width: 100%;
  color: #333;
} */

.react-datepicker{
  position: absolute;
  top: 40px;
  left: 0px;
  border: none;
  background-color: #eee;
  border-radius: 0 0 10px 10px;
  filter: drop-shadow(0 5px 0px #33333311);
}
.react-datepicker__navigation{
  display: none;
}
.react-datepicker__day.react-datepicker__day--selected{
  background-color: #2ab7ff;
  border-radius: 2px;
}
.react-datepicker__day--keyboard-selected{
  background-color: transparent;
  color: inherit;
}
.react-datepicker__day{
  background-color: #fff;
  margin: 2px;
  padding: 1px;
  font-size: 13px;
  width: 25px;
}
.react-datepicker__day-names{
  display: none;
}
.react-datepicker__header{
  border: none;
  display: none;
  background-color: transparent;
}
.react-datepicker__current-month{
  display: none;
}
#react-datepicker-button{
  height: 40px;
  min-width: 150px;
  border-radius: 50px;
  color: #333;
}

#dateBox{
  position: relative;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #ededed;
  height: 100%;
  min-width: 200px;

  opacity: 0.7;
  transition: .2s;
}
#dateBox:hover{
  opacity: 1;
  background: #d3d3d3;
}

#dateBox-selector{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;

  border: none;

  outline-color: gray;
}
#dateBox-selector::-webkit-calendar-picker-indicator{
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
#dateBox-selector::-webkit-datetime-edit-text{
  opacity: 0;
}
::-webkit-datetime-edit{
  opacity: 0;
}
::-webkit-datetime-edit-fields-wrapper{
  opacity: 0;
}
#dateBox-selector::-webkit-clear-button {
  opacity: 0;
}
#dateBox-selector::-webkit-calendar-picker-indicator { 
  display: none 
}

#timeBox{
  position: relative;
  max-width: 700px;
  flex-grow: 1;
}
#timeBox-slider{
  position: relative;
  z-index: 2;
  appearance: none;
  height: 10px;
  width: 100%;
  background: #d3d3d3;
  opacity: 0.8;
  border-radius: 10px;
  cursor: pointer;
  transition: opacity .2s;
}
#timeBox-slider:hover{
  opacity: 1;
}
#timeBox-slider::-webkit-slider-thumb{
  appearance: none;
  width: 25px;
  height: 25px;
  background: #9a9a9a;
  border-width: 10px;
  border-color: #eee;
  border-style: double;
  border-radius: 50%;
  cursor: grab;
}
.sliderticks {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.sliderticks p {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #d3d3d3;
  margin: 0 0 10px 0;
  pointer-events: none;
}


#speedBox{
  position: relative;
  display: flex;
  flex-direction: row;
  height: 40px;
}
#speedBox-input{
  width: 40px;
  background: #efefef;
  border: none;
  text-align: center;
}
#speedBox button{
  opacity: 0.7;
  background: #ededed;

  transition: .2s;
}
#speedBox button:hover{
  opacity: 1;
  background: #d3d3d3;
}
.label{
  width: 30px;
  height: 100%;
  object-fit: contain;
}



.mobile-slider{
  position: absolute;
  bottom: 0;
  right: 0;

  display: none;

  margin: 20px;
  background: #ffffffee;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  
  overflow: hidden;
}
.mobile-slider-thumb-wrapper{
  width: 100%;
  height: 100%;
}
.mobile-slider-thumb{
  position: relative;
  background: #333;
  border: solid 10px gray;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: auto;
  cursor: pointer;
  touch-action: none; /* Facebook Messenger is broken. */
}


@media screen and (max-width: 840px) {
  #speedBox{
    display: none;
  }
}


@media screen and (max-width: 600px) {
  #timeBox{
    display: none;
  }
  .mobile-slider{
    display: block;
  }
  .keyboardKey{
    display: none;
  }

  #info-modal{
    position: absolute;
    left: 0;
    margin: auto;
    background: transparent;

    display: flex;
    flex-direction: row;
  }
  #details{
    display: none;
  }
  #dbIndicator{
    position: relative;
    left: 0;
  }

  .react-datepicker{
    position: fixed;
    z-index: 2;
    top: 50px;
    left: calc(50% - 100px);
  }
}

@media screen and (max-width: 395px) {
  #previous-button, #next-button{
    display: none;
  }
  #dateBox{
    background: transparent;
  }
}