.loading{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#00b7ff 0%, #3bced3 50%);
    color: white;
    border: solid 10px white;
    border-radius: 20px;
    box-sizing: border-box;
    opacity: 0.2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading h1{
    margin: 0;
    opacity: 0.8;
}

.snowballs{
    position: relative;
    width: 80px;
    height: 150px;
    overflow: hidden;
    margin: 0;
}

.snowball{
    position: absolute;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    opacity: 0;
    
    animation: snow 1s infinite linear;
}
.snowball:nth-child(0){
    top: 0px;
}
.snowball:nth-child(1){
    top: 0;
    right: 0;
    animation-delay: 0.5s;
}
.snowball:nth-child(2){
    top: 0;
    animation-delay: 1s;
}
.snowball:nth-child(4){
    top: 0;
    right: 0;
    animation-delay: 1.5s;
}

@keyframes snow{
    0%{
        width: 20px;
        height: 20px;
        opacity: 1;
        transform: translateY(0px);
    }
    100%{
        opacity: 0;
        transform: translateY(100px);
    }
}